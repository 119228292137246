import tinycolor from '@ctrl/tinycolor';
import {
  CARD_BACKGROUND_COLOR,
  CARD_BORDER_COLOR,
  CARD_BORDER_WIDTH,
  getLayoutType,
  isLayoutFullPost,
  IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED,
  LINK_AND_HASHTAG_COLOR,
  POST_HEADER_FIVE_COLOR,
  POST_HEADER_FIVE_FONT,
  POST_HEADER_FOUR_COLOR,
  POST_HEADER_FOUR_FONT,
  POST_HEADER_SIX_COLOR,
  POST_HEADER_SIX_FONT,
  POST_HEADER_THREE_COLOR,
  POST_HEADER_THREE_FONT,
  POST_HEADER_TWO_COLOR,
  POST_HEADER_TWO_FONT,
  POST_PAGE_FONT,
  POST_QUOTES_COLOR,
  POST_QUOTES_FONT,
  POST_TEXT_COLOR,
  POST_TITLE_COLOR,
  POST_TITLE_FONT,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import { find, get, pick } from 'lodash';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import { getComponentRef } from './magic-migration/sdk-utils';

const transformColor = (color, siteColors) => {
  // This is copied from communities-blog-settings, need a different function because a different tinycolor is used in settings
  // TODO: Unify tinycolor version in settings and editor-app
  if (!color.themeName) {
    return {
      value: {
        rgba: color.value,
        opacity: tinycolor(color.value).isValid ? tinycolor(color.value).getAlpha() : 1,
      },
    };
  }

  const siteColor = find(siteColors, ({ reference }) => reference === color.value || reference === color.themeName);
  const themeName = get(siteColor, 'name', color.themeName);
  const colorValue = get(siteColor, 'value', color.value);

  return {
    value: {
      rgba: colorValue,
      opacity: tinycolor(color.value).isValid ? tinycolor(color.value).getAlpha() : 1,
      ...(themeName && { color: { name: themeName } }),
    },
  };
};

const stylesToCopy = {
  fonts: [
    POST_HEADER_TWO_FONT,
    POST_HEADER_THREE_FONT,
    POST_HEADER_FOUR_FONT,
    POST_HEADER_FIVE_FONT,
    POST_HEADER_SIX_FONT,
    POST_QUOTES_FONT,
    POST_TITLE_FONT,
    POST_PAGE_FONT,
  ],
  colors: [
    POST_HEADER_TWO_COLOR,
    POST_HEADER_THREE_COLOR,
    POST_HEADER_FOUR_COLOR,
    POST_HEADER_FIVE_COLOR,
    POST_HEADER_SIX_COLOR,
    POST_QUOTES_COLOR,
    POST_TITLE_COLOR,
    POST_TEXT_COLOR,
    LINK_AND_HASHTAG_COLOR,
    CARD_BORDER_COLOR,
    CARD_BACKGROUND_COLOR,
  ],
  booleans: [IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED],
  numbers: [CARD_BORDER_WIDTH],
};

export const getMappedPostPageStyleParams = async ({ sdk, appToken }) => {
  const postPageRef = await getComponentRef(sdk, POST_WIDGET_ID);
  const postPageStyleParams = await sdk.document.tpa.getStyleParams(appToken, { compRef: postPageRef });
  const siteColors = await sdk.document.tpa.getSiteColors(appToken, { compRef: postPageRef });

  const postPageFonts = pick(postPageStyleParams.fonts, stylesToCopy.fonts);
  const postPageBooleans = pick(postPageStyleParams.booleans, stylesToCopy.booleans);
  const postPageColors = pick(postPageStyleParams.colors, stylesToCopy.colors);
  const postPageNumbers = pick(postPageStyleParams.numbers, stylesToCopy.numbers);

  const fonts = Object.keys(postPageFonts).map((key) => ({
    type: 'font',
    key,
    param: { value: postPageFonts[key] },
  }));

  const booleans = Object.keys(postPageBooleans).map((key) => ({
    type: 'boolean',
    key,
    param: { value: postPageBooleans[key] },
  }));

  const colors = Object.keys(postPageColors).map((key) => ({
    type: 'color',
    key,
    param: transformColor(postPageColors[key], siteColors),
  }));

  const numbers = Object.keys(postPageNumbers).map((key) => ({
    type: 'number',
    key,
    param: { value: postPageNumbers[key] },
  }));

  return [...fonts, ...booleans, ...colors, ...numbers];
};

export const setFullpostStyleParams = async (context) => {
  const { sdk, appToken } = context;
  const feedRef = await getComponentRef(sdk, BLOG_WIDGET_ID);
  const feedStyleParams = await sdk.document.tpa.getStyleParams(appToken, { compRef: feedRef });
  const layoutType = feedStyleParams.numbers[getLayoutType(SECTION_HOMEPAGE)];
  const layoutFullPost = isLayoutFullPost(layoutType);

  if (!layoutFullPost) {
    return;
  }

  const styleParams = await getMappedPostPageStyleParams(context);
  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: feedRef,
    styleParams,
  });
}
